import { ROLES } from '@app/core/constants/roles.constants';
import { PATHS } from '@core/interfaces/utils/router-path';

const CONTROL_FLETES = `/${PATHS.MAIN}/${PATHS.UN.MARCAS.BASE}/${PATHS.CONTROL_DE_FLETES.BASE}`;
const OFERTA_CAMIONES = `/${PATHS.MAIN}/${PATHS.OFERTA_DE_CAMIONES.BASE}`;
const ORDENES_CARGA = `/${PATHS.MAIN}/${PATHS.ORDENES_DE_CARGA.BASE}`;
const CARGA_FACTURAS_REMITOS = `/${PATHS.MAIN}/${PATHS.UN.MARCAS.BASE}/${PATHS.CARGA_FACTURAS_Y_REMITOS.BASE}`;

// Rutas para redireccionar despues del login
export const ROUTES = {
  [ROLES.ADMINISTRADOR]: CONTROL_FLETES,
  [ROLES.ADMINISTRADOR_LOGISTICA]: CONTROL_FLETES,
  [ROLES.ADMINISTRADOR_UNAGRO]: CONTROL_FLETES,
  [ROLES.USUARIO_CHOFER]: OFERTA_CAMIONES,
  [ROLES.USUARIO_LIQUIDADOR]: CONTROL_FLETES,
  [ROLES.USUARIO_PROGRAMADOR]: OFERTA_CAMIONES,
  [ROLES.USUARIO_SUPERVISOR]: CONTROL_FLETES,
  [ROLES.USUARIO_TRANSPORTE]: CONTROL_FLETES,
  [ROLES.VERIFICADOR_PALLETS]: CARGA_FACTURAS_REMITOS,
};
